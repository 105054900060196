import "./Logo.css"


export default function Logo() {
  return (
    <div className="logo">
      <h1>LAZYWITHCLASS</h1>
      <p>I move text for a living</p>
    </div>

  )
}
